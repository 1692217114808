.component-info-section {
    background-color: var(--white);
    box-shadow: 2px 2px 4px 0 var(--shadow);
    padding: 1rem;
    border-radius: 10px;
    margin-bottom: 1rem;
    overflow: auto;
    margin-top: 3rem;
}

.section-header {
    padding-top: 0;
}

.example-label {
    padding-top: 1rem;
    margin-bottom: 1rem;
}

.example-tool {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.example {
    margin-bottom: 1rem;
    justify-content: space-between;
    flex-wrap: wrap;
}

.props-optional {
    background-color: var(--absolute-zero);
    padding: 0.5rem;
    border-radius: 5px;
}
