.portal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    color: transparent;
    background-color: transparent;
    opacity: 0;
    transition-property: color, background-color, opacity;
    transition-duration: 5s;
    border-radius: 5px;
}

.portal-content-visible {
    color: var(--raisin-black);
    background-color: var(--beau-blue);
    opacity: 1;
}

.portal-icon {
    margin-right: 0.5rem;
    font-size: 1.5rem;
}
