.example-guard-area {
    padding: 1rem;
    background-color: var(--platinum);
}

.guarded-area-header {
    margin-top: 0rem;
    margin-bottom: 2rem;
}

.guarded-switch-area {
    display: flex;
    align-items: center;
}

.guarded-area-label {
    margin: 0;
}
