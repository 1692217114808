.header-bg {
    background: rgb(235,235,235);
    background: linear-gradient(90deg, var(--platinum) 0%, var(--cultured) 75%);
}

.breadcrumb {
    text-transform: uppercase;
    font-size: var(--font-size-breadcrumb);
    color: var(--onyx);
    padding-top: 3vw;
}

.header-text {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 3vw;
    padding-bottom: 3vw;
}

.description {
    padding-bottom: 3vw;
    margin-bottom: 0;
}

.breadcrumb-included {
    margin-top: 0.5rem;
    padding-top: 0;
}

.description-included {
    margin-bottom: 0.5rem;
    padding-bottom: 0;
}
