.overlay {
    visibility: hidden;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: var(--black);
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.1s;
    z-index: 100;
}

.overlay-visible {
    visibility: visible;
    opacity: 0.75;
}
