.badge {
    display: inline-flex;
    border-radius: 5px;
    padding: 0.25rem 0.5rem;
    align-items: center;
}


.badge-indigo-dark {
    background-color: var(--indigo);
    color: var(--cultured);
}

.badge-indigo-light {
    background-color: var(--mauve);
    color: var(--raisin-black);
}


.badge-green-dark {
    background-color: var(--dark-green);
    color: var(--cultured);
}

.badge-green-light {
    background-color: var(--nyanza);
    color: var(--raisin-black);
}


.badge-blue-dark {
    background-color: var(--absolute-zero);
    color: var(--cultured);
}

.badge-blue-light {
    background-color: var(--beau-blue);
    color: var(--raisin-black);
}


.badge-yellow-dark {
    background-color: var(--honey-yellow);
    color: var(--raisin-black);
}

.badge-yellow-light {
    background-color: var(--lemon-chiffon);
    color: var(--raisin-black);
}


.badge-red-dark {
    background-color: var(--vivid-burgundy);
    color: var(--cultured);
}

.badge-red-light {
    background-color: var(--misty-rose);
    color: var(--raisin-black);
}


.badge-neutral-dark {
    background-color: var(--onyx);
    color: var(--cultured);
}

.badge-neutral-light {
    background-color: var(--light-gray);
    color: var(--raisin-black);
}