.labelish {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.contrast-icon {
    margin-left: 0.25rem;
    font-size: 1.5rem;
}

.badge-area {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
}

.first-threshold {
    margin-top: 1rem;
}

.threshold-detail-list {
    margin-bottom: 1rem;
}

.regular-normal-preview,
.regular-bold-preview,
.large-normal-preview,
.large-bold-preview
{
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
    border: 1px solid var(--silver);
}

/* At most 17pt, normal font weight */
.regular-normal-preview {
    font-size: 12pt;
    font-weight: normal;
}

/* At most 13pt, bold font weight */
.regular-bold-preview {
    font-size: 9pt;
    font-weight: bold;
}

/* At least 18pt, normal font weight */
.large-normal-preview {
    font-size: 18pt;
    font-weight: normal;
}

/* at least 14pt, bold font weight */
.large-bold-preview {
    font-size: 14pt;
    font-weight: bold;
}
