/*
GLOBAL STYLES
--------------------------------------------------
[Table of Contents]

1. Variables
2. Base-CSS Styles
3. Tablets
4. Laptops
5. Large Devices
6. Helper Classes
--------------------------------------------------
*/


/*
--------------------------------------------------
[1. Variables]
--------------------------------------------------
*/
:root {
    /* Primary Colors */
    --indigo: #320D82;
    --raisin-black: #242124;
    --cultured: #F9F9F9;

    /* Indigos */
    --midnight-blue: #240A5C;
    --purple: #5526BA;
    --plump-purple: #6035B5;
    --medium-purple: #9466DF;
    --bright-lilac: #D093FF;
    --mauve: #E6C8FF;
    --pale-purple: #EFDFFE;
    --ghost-white: #F8F6FE;


    /* Greens */
    --lincoln-green: #005213;
    --dark-green: #006200;
    --mantis: #61C159;
    --nyanza: #DDF9D1;

    /* Blues */
    --denim-blue: #093EAE;
    --absolute-zero: #0046C8;
    --sky-blue: #7BAFFF;
    --beau-blue: #CEE5FD;

    /* Yellows */
    --gamboge: #EC9C1C;
    --honey-yellow: #FFAE00;
    --sunglow: #FFC83F;
    --lemon-chiffon: #FFF5CC;

    /* Reds */
    --claret: #7C1331;
    --vivid-burgundy: #A41533;
    --light-coral: #FF8A91;
    --misty-rose: #F9D8D0;

    /* Neutrals */
    --black: #000000;
    --eerie-black: #151515;
    --jet: #2A2A2A;
    --onyx: #3E3E3E;
    --davys-grey: #535353;

    --silver-chalice: #ADADAD;
    --silver: #C2C2C2;
    --light-gray: #D6D6D6;
    --platinum: #EBEBEB;
    --white: #FFFFFF;

    /* Helper Vars */
    --shadow: rgba(0, 0, 0, 0.15);
    /* 16px - 18px */
    --font-size-body: clamp(1rem, 0.9431818181818181rem + 0.2840909090909091vw, 1.125rem);
    /* 32px - 48px */
    --font-size-h1: clamp(2rem, 1.5454545454545454rem + 2.272727272727273vw, 3rem);
    /* 26px - 36px */
    --font-size-h2: clamp(1.625rem, 1.3409090909090908rem + 1.4204545454545456vw, 2.25rem);
    /* 22px - 28px */
    --font-size-h3: clamp(1.375rem, 1.2045454545454546rem + 0.8522727272727272vw, 1.75rem);
    /* 18px */
    --font-size-h4: 1.125rem;
    /* 16px */
    --font-size-h5: 1rem;
    /* 12px - 16px */
    --font-size-breadcrumb: clamp(12px, calc(0.75rem + ((1vw - 3.2px) * 0.5682)), 16px);
    --title-font: 'Bitter', serif;
    --header-font: 'Karla', sans-serif;
    --body-font: 'Karla', sans-serif;
    --code-font: 'Inconsolata', monospace;
}


/*
--------------------------------------------------
[2. Base-CSS Styles]

Mobile-First Design
Base CSS is for smallest devices (320px width)
--------------------------------------------------
*/
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    height: 100%;
    width: 100%;
    background-color: var(--cultured);
    color: var(--raisin-black);
    font-family: var(--body-font);
    font-size: var(--font-size-body);
}

:focus-visible {
    outline: 2px solid var(--absolute-zero);
    outline-offset: 2px;
}

:focus:not(:focus-visible) {
    outline: none;
}

h1, h2, h3, h4 {
    font-family: var(--header-font);
    color: var(--midnight-blue);
}

h1 {
    font-family: var(--title-font);
    font-size: var(--font-size-h1);
    margin-top: 2rem;
    margin-bottom: 1rem;
}

h2 {
    font-size: var(--font-size-h2);
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}

h3 {
    font-size: var(--font-size-h3);
    margin-top: 1.25rem;
    margin-bottom: 1rem;
}

h4 {
    font-size: var(--font-size-h4);
    color: var(--raisin-black);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

a {
    color: var(--plump-purple);
}

a:visited {
    color: var(--midnight-blue);
}

.padding-top-0 {
    padding-top: 0;
}

.padding-right-0 {
    padding-right: 0;
}

.padding-bottom-0 {
    padding-bottom: 0;
}

.padding-left-0 {
    padding-left: 0;
}

.margin-top-0 {
    margin-top: 0;
}

.margin-right-0 {
    margin-right: 0;
}

.margin-bottom-0 {
    margin-bottom: 0;
}

.margin-left-0 {
    margin-left: 0;
}

ul {
    padding-left: 2rem;
}

li {
    margin-bottom: 0.5rem;
}

label {
    display: inline-block;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

p {
    margin-bottom: 1rem;
    line-height: 1.5rem;
}

hr {
    height: 1px;
    background-color: var(--silver);
    border: none;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.body-text {
    margin-left: 1rem;
    margin-right: 1rem;
}

.content-clamp,
.content-clamp-more {
    padding-left: 1rem;
    padding-right: 1rem;
}

/*
--------------------------------------------------
[3. Tablets]
--------------------------------------------------
*/
@media (min-width: 768px) {
    .body-text {
        margin-left: 0;
        margin-right: 0;
    }

    .content-clamp {
        width: clamp(18rem, 90vw, 66rem);
        margin-left: auto;
        margin-right: auto;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .content-clamp-more {
        width: clamp(18rem, 90vw, 66rem);
        margin-left: auto;
        margin-right: auto;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .content-section {
        border-radius: 10px;
    }
}


/*
--------------------------------------------------
[4. Laptops]
--------------------------------------------------
*/
@media (min-width: 1024px) {

}


/*
--------------------------------------------------
[5. Large Devices]
--------------------------------------------------
*/
@media (min-width: 1440px) {

}


/*
--------------------------------------------------
[6. Helper Classes]
--------------------------------------------------
*/


/* Calculating Font Clamp 48.89
Note:   Everything must be in rem. Including screen widths
        1200 = 66.67, 320 = 17.78
slope = (maxFontSize - minFontSize) / (maxWidth - minWidth)
yAxisIntersection = -minWidth * slope + minFontSize
preferredValue = yAxisIntersection[rem] + (slope * 100)[vw]
 */