.option-group {
    margin-bottom: 1rem;
}

.option-group-label {
    margin: 1rem 0.5rem 0.5rem 0.5rem;
    border-left: 3px solid transparent;
    font-weight: bold;
    display: flex;
    align-items: center;
    color: var(--midnight-blue);
}

.option-group-label-content {
    padding-right: 0.5rem;
}
