.select-container {
    position: relative;
}

.select-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3rem;
    width: 100%;
    padding: 0.5rem;
    cursor: pointer;
    background-color: var(--white);
    border: 1px solid var(--silver);
    border-radius: 3px;
    font-size: 1rem;
    font-family: var(--body-font);
}

.select-regular {
    max-width: 320px;
}

.select-large {
    max-width: 768px;
}

.select-full {
    max-width: 100%;
}

.select-input:disabled {
    cursor: not-allowed;
}

.select-options {
    position: absolute;
    display: none;
    padding: 0 0;
    background-color: var(--white);
    box-shadow: 0 4px 8px var(--shadow);
    border: 1px solid var(--silver);
    border-radius: 3px;
    max-height: 370px;
    overflow-y: auto;
    list-style: none;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.3s;
    z-index: 100;
}

.select-options-on-dom {
    display: block;
}

.select-options-visible {
    opacity: 1;
}

.no-options-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    padding: 0.5rem 0;
    color: var(--davys-grey);
}

.select-label-content {
    padding-right: 0.5rem;
}

.select-caret-icon {
    margin-left: auto;
}