.collapsible-toggle {
    position: relative;
    width: 100%;
    height: 3rem;
    padding: 0.5rem;
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    border-radius: 3px;
    font-size: 1rem;
    font-family: var(--body-font);
    background-color: var(--white);
    border: 1px solid var(--silver);
    color: var(--raisin-black);
}

.collapsible-toggle-parent-controlled,
.collapsible-toggle-open,
.collapsible-toggle:hover {
    background-color: var(--light-gray);
}

.collapsible-toggle-parent-controlled {
    cursor: not-allowed;
}

.collapsible-toggle-disabled,
.collapsible-toggle-disabled:hover {
    background-color: var(--platinum);
    color: var(--silver-chalice);
    cursor: not-allowed;
}

.collapsible {
    overflow: hidden;
    max-height: 0;
    transition-property: max-height;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
}

.collapsible-stacked {
    margin-top: -1px;
}