.tab-list {
    display: flex;
    border-collapse: collapse;
    position: relative;
    margin-bottom: 1.5rem;
}

/* Tab list / tab panel divider */
.tab-list::before {
    content: "";
    position: absolute;
    height: 1px;
    background-color: var(--silver);
    bottom: 0;
    left: 0;
    right: 0;
}

.tab-button {
    padding: 0.75rem 1rem;
    border: none;
    background-color: transparent;
    position: relative;
    font-size: 1rem;
    font-family: var(--body-font);
}

.tab-button:hover {
    cursor: pointer;
}

.tab-button-active {
    font-weight: bold;
}

.tab-panel-inactive {
    display: none;
}

.tab-button:disabled {
    cursor: not-allowed;
    color: var(--silver-chalice);
}

.tab-button:disabled::after {
    display: none;
}

/* Tab bottom border colors */
.tab-button::after {
    background-color: transparent;
    transition-property: background-color;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
}

.tab-button:hover::after,
.tab-button-active::after {
    content: "";
    position: absolute;
    height: 4px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--indigo);
}

.tab-button:hover::after {
    background-color: var(--silver);
}

.tab-button-active::after,
.tab-button-active:hover::after {
    background-color: var(--indigo);
}
