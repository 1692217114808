.option {
    padding: 0.25rem 0.5rem;
    margin: 0;
    cursor: pointer;
    border-left: 3px solid transparent;
    display: flex;
    align-items: center;
    color: var(--davys-grey);
}

.option:hover {
    color: var(--raisin-black);
    background-color: var(--light-gray);
}

.option-focused {
    color: var(--raisin-black);
    background-color: var(--light-gray);
    border-left: 3px solid var(--indigo);
}

.option-selected {
    color: var(--raisin-black);
    background-color: var(--light-gray);
    font-weight: bold;
}

.selected-icon {
    margin-left: auto;
    color: var(--indigo);
}

.option-label-content {
    padding-right: 0.5rem;
}

.option-grouped {
    padding-left: 1.5rem;
}