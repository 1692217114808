.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.error-code {
    margin-top: 3rem;
    font-size: 10rem;
    font-family: var(--title-font);
}

.error-name {
    font-size: 2rem;
}

.error-description {
    margin-top: 1rem;
}