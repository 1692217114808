.nav-list {
    list-style: none;
    padding: 0;
}

.nav-list-item {
    margin: 0;
}

.nav-button,
.nav-nested-button {
    padding-top: 1rem;
    padding-bottom: 1rem;
    line-height: 1.5rem;
    background-color: var(--indigo);
    color: var(--cultured);
    border: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--body-font);
}

.nav-nested-button {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    background-color: var(--midnight-blue);
}

.nav-button:hover,
.nav-button:focus {
    background-color: var(--purple);
    outline: none;
}

.nav-nested-button:hover,
.nav-nested-button:focus {
    background-color: var(--purple);
    outline: none;
}

.nav-icon {
    font-size: 1.5rem;
    padding-right: 0.5rem;
}

.nav-nested-icon {
    font-size: 1rem;
    padding-right: 0.5rem;
}

.nav-label {
    font-size: 1.5rem;
    font-weight: bold;
}

.nav-nested-label {
    font-size: 1.2rem;
}

.nav-collapsed-links {
    margin: 0;
    overflow: hidden;
    background-color: var(--midnight-blue);
    max-height: 0;
    transition: max-height 0.5s;
}
