.table-container > .table-sm:nth-child(even),
.table-container > .table-med:nth-child(even) {
    background-color: var(--light-gray);
}

.table-container > .table-sm:first-child,
.table-container > .table-med:first-child {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.table-container > .table-sm:last-child,
.table-container > .table-med:last-child {
    border-bottom: 1px solid var(--silver);
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.table-sm,
.table-med {
    background-color: var(--white);
    border-top: 1px solid var(--silver);
    border-right: 1px solid var(--silver);
    border-left: 1px solid var(--silver);
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    width: 100%;
}

.table-med {
    padding-top: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
}

.table-lg {
    width: 100%;
    border-collapse: collapse;
}

.header-sm,
.header-med {
    color: var(--midnight-blue);
    text-align: left;
    padding-bottom: 0.5rem;
}

.header-med {
    padding-bottom: 1rem;
}

.header-row-lg > .header-lg:first-child {
    border-top-left-radius: 3px;
}

.header-row-lg > .header-lg:last-child {
    border-top-right-radius: 3px;
}

.header-lg {
    background-color: var(--indigo);
    color: var(--white);
    padding: 1rem;
}

.tbody-lg > tr:nth-child(even) {
    background-color: var(--light-gray);
}

.tbody-lg > .row-lg:last-child > .cell-lg {
    /* Border Bottom */
    box-shadow: inset 0 -1px 0 0 var(--silver);
}

.tbody-lg > .row-lg:last-child > .cell-lg:first-child {
    /* Border Bottom Left */
    box-shadow: inset 1px -1px 0 0 var(--silver);
    border-bottom-left-radius: 3px;
}

.tbody-lg > .row-lg:last-child > .cell-lg:last-child {
    /* Border Bottom Right */
    box-shadow: inset -1px -1px 0 0 var(--silver);
    border-bottom-right-radius: 3px;
}

.row-lg > .cell-lg:first-child {
    /* Border Left */
    box-shadow: inset 1px 0 0 0 var(--silver);
}

.row-lg > .cell-lg:last-child {
    /* Border Right */
    box-shadow: inset -1px 0 0 0 var(--silver);
}

.cell-sm,
.cell-med {
    padding-left: 1rem;
    padding-bottom: 0.5rem;
}

.cell-med {
    padding-bottom: 1rem;
    width: 100%;
}

.cell-lg {
    padding: 0.5rem;
}
