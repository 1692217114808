.button-component {
    position: relative;
    border-radius: 5px;
    padding: 0.75rem;
    font-size: 1rem;
    font-family: var(--body-font);
    height: 2.5rem;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-width: 2px;
    border-style: solid;
    margin-right: 1rem;
}

.primary-variant {
    color: var(--cultured);
    background-color: var(--indigo);
    border-color: var(--indigo);
}

.primary-variant-light {
    color: var(--raisin-black);
    background-color: var(--mauve);
    border-color: var(--mauve);
}

.success-variant {
    color: var(--cultured);
    background-color: var(--dark-green);
    border-color: var(--dark-green);
}

.success-variant-light {
    color: var(--raisin-black);
    background-color: var(--nyanza);
    border-color: var(--nyanza);
}

.info-variant {
    color: var(--cultured);
    background-color: var(--absolute-zero);
    border-color: var(--absolute-zero);
}

.info-variant-light {
    color: var(--raisin-black);
    background-color: var(--beau-blue);
    border-color: var(--beau-blue);
}

.success-variant-light {
    color: var(--raisin-black);
    background-color: var(--nyanza);
    border-color: var(--nyanza);
}

.warning-variant {
    color: var(--raisin-black);
    background-color: var(--honey-yellow);
    border-color: var(--honey-yellow);
}

.warning-variant-light {
    color: var(--raisin-black);
    background-color: var(--lemon-chiffon);
    border-color: var(--lemon-chiffon);
}

.danger-variant {
    color: var(--cultured);
    background-color: var(--vivid-burgundy);
    border-color: var(--vivid-burgundy);
}

.danger-variant-light {
    color: var(--raisin-black);
    background-color: var(--misty-rose);
    border-color: var(--misty-rose);
}

.primary-variant:disabled,
.primary-variant:disabled:hover,
.primary-variant-light:disabled,
.primary-variant-light:disabled:hover,
.success-variant:disabled,
.success-variant:disabled:hover,
.success-variant-light:disabled,
.success-variant-light:disabled:hover,
.info-variant:disabled,
.info-variant:disabled:hover,
.info-variant-light:disabled,
.info-variant-light:disabled:hover,
.warning-variant:disabled,
.warning-variant:disabled:hover,
.warning-variant-light:disabled,
.warning-variant-light:disabled:hover,
.danger-variant:disabled,
.danger-variant:disabled:hover,
.danger-variant-light:disabled,
.danger-variant-light:disabled:hover{
    color: var(--silver-chalice);
    background-color: var(--platinum);
    border-color: var(--platinum);
    cursor: not-allowed;
}

.primary-variant:hover,
.primary-variant.loading:disabled {
    background-color: var(--midnight-blue);
    border-color: var(--midnight-blue);
}

.primary-variant-light:hover,
.primary-variant-light.loading:disabled {
    background-color: var(--bright-lilac);
    border-color: var(--bright-lilac);
}

.success-variant:hover,
.success-variant.loading:disabled {
    background-color: var(--lincoln-green);
    border-color: var(--lincoln-green);
}

.success-variant-light:hover,
.success-variant-light.loading:disabled {
    background-color: var(--mantis);
    border-color: var(--mantis);
}

.info-variant:hover,
.info-variant.loading:disabled {
    background-color: var(--denim-blue);
    border-color: var(--denim-blue);
}

.info-variant-light:hover,
.info-variant-light.loading:disabled {
    background-color: var(--sky-blue);
    border-color: var(--sky-blue);
}

.warning-variant:hover,
.warning-variant.loading:disabled {
    background-color: var(--gamboge);
    border-color: var(--gamboge);
}

.warning-variant-light:hover,
.warning-variant-light.loading:disabled {
    background-color: var(--sunglow);
    border-color: var(--sunglow);
}

.danger-variant:hover,
.danger-variant.loading:disabled {
    background-color: var(--claret);
    border-color: var(--claret);
}

.danger-variant-light:hover,
.danger-variant-light.loading:disabled {
    background-color: var(--light-coral);
    border-color: var(--light-coral);
}

.secondary-variant {
    color: var(--raisin-black);
    background-color: var(--cultured);
    border-color: var(--raisin-black);
}

.secondary-variant:disabled,
.secondary-variant:disabled:hover {
    color: var(--silver-chalice);
    background-color: var(--cultured);
    border-color: var(--silver-chalice);
    cursor: not-allowed;
}

.secondary-variant:hover,
.secondary-variant.loading:disabled {
    background-color: var(--light-gray);
    border-color: var(--raisin-black);
}

.minimal-variant {
    color: var(--raisin-black);
    background-color: transparent;
    border-color: transparent;
}

.minimal-variant:disabled,
.minimal-variant:disabled:hover {
    color: var(--silver-chalice);
    background-color: transparent;
    border-color: transparent;
    cursor: not-allowed;
}

.minimal-variant:hover,
.minimal-variant.loading:disabled {
    background-color: var(--light-gray);
    border-color: var(--light-gray);
}

.hidden-text {
    visibility: hidden;
}

/* Loading Spinner */
.loader-container {
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader {
    border: 3px solid transparent;
    border-top: 3px solid var(--cultured);
    border-radius: 50%;
    width: 1.75rem;
    height: 1.75rem;
    animation: spin 1s running infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.warning-variant > .loader-container > .loader,
.secondary-variant > .loader-container > .loader,
.minimal-variant > .loader-container > .loader {
    border-top: 3px solid var(--raisin-black);
}
