.site-banner {
    background-color: var(--indigo);
    color: var(--cultured);
    grid-area: header;
    display: grid;
    grid-template-columns: 4rem 1fr 4rem;
    align-items: center;
    justify-items: center;
    height: 6rem;
    font-family: var(--header-font);
}

.nav-drawer-toggle {
    height: 6rem;
    width: 4rem;
    cursor: pointer;
    font-size: 1.5rem;
    background-color: var(--indigo);
    color: var(--cultured);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.nav-drawer-toggle-menu-text {
    display: none;
}

.nav-drawer-toggle:hover,
.nav-drawer-toggle:focus {
    background-color: var(--purple);
    outline: none;
}

.logo {
    font-family: var(--title-font);
    font-size: var(--font-size-h2);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--cultured);
    text-decoration: none;
}

.logo:visited {
    color: var(--cultured);
}

.logo-trim {
    display: none;
}

.logo-img {
    width: clamp(2rem, 5vw, 3rem);
    height: auto;
}

.logo-text {
    margin-left: 0.5rem;
}

.releases-link,
.releases-link:visited {
    width: 4rem;
    height: 6rem;
    color: var(--cultured);
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.releases-link:hover,
.releases-link:focus {
    background-color: var(--purple);
    outline: none;
}

@media (min-width: 768px) {
    .site-banner {
        grid-template-columns: 7rem 1fr 7rem;
    }

    .nav-drawer-toggle {
        width: 7rem;
    }

    .logo-trim {
        display: inline;
    }

    .nav-drawer-toggle-menu-text {
        display: block;
        font-size: 1rem;
    }

    .releases-link,
    .releases-link:visited {
        width: 7rem;
    }
}
