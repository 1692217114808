.color-card-container {
    background-color: var(--white);
    box-shadow: 2px 2px 4px 0 var(--shadow);
    padding: 1rem;
    border-radius: 10px;
    list-style: none;
    width: 240px;
}

.color-card {
    height: 100px;
    width: 100%;
    border-radius: 10px;
    padding: 0.5rem;
    margin-bottom: 1rem;
    display: flex;
}

.color-name {
    font-family: var(--code-font);
}

.color-card-header {
    margin-bottom: 0.25rem;
}
