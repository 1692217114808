.container-description {
    margin-bottom: 0.5rem;
}

.small-container {
    border: 1px solid var(--silver);
    border-radius: 3px;
    height: 200px;
    overflow: hidden;
    padding: 1rem;
    margin-bottom: 1rem;
}
