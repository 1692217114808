.example-switch-div {
    color: var(--raisin-black);
    background-color: var(--cultured);
    display: flex;
    align-items: center;
}

.example-switch-label {
    margin: 0;
}
