.switch {
    position: relative;
    box-sizing: content-box;
    background-clip: content-box;
    padding: 1px;
    margin: 2px;
    background-color: var(--silver);
    border-radius: 20px;
    border: 2px solid transparent;
    width: 40px;
    height: 20px;
    cursor: pointer;
    transition-property: background-color;
    transition-duration: 0.4s;
    transition-timing-function: ease;
}

.switch:disabled {
    cursor: not-allowed;
}

.switch-toggled {
    background-color: var(--indigo);
}

.switch::before {
    position: absolute;
    content: "";
    background-color: var(--white);
    border-radius: 50%;
    height: 16px;
    width: 16px;
    left: 3px;
    bottom: 3px;
    transition-property: transform;
    transition-duration: 0.4s;
    transition-timing-function: ease;
}

.switch-toggled::before {
    transform: translate(20px);
}

.lock-icon,
.lock-icon-toggled {
    position: absolute;
    top: 5px;
    height: 12px;
    width: 10px;
}

.lock-icon {
    right: 7px;
    color: var(--raisin-black);
}

.lock-icon-toggled {
    left: 7px;
    color: var(--cultured)
}
