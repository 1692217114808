.top-paragraph {
    margin-top: 1rem;
}

.contact-group {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.image-container {
    display: flex;
    justify-content: center;
}

.details-container {
    background-color: var(--light-gray);
    display: flex;
    justify-content: center;
    padding: 1rem;
    border-radius: 3px;
}

.self-photo {
    width: clamp(100px, 25vw, 300px);
    height: auto;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.contact-list {
    list-style: none;
}

.contact-list > li {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width: 768px) {
    .contact-group {
        flex-direction: row;
        justify-content: center;
    }

    .details-container {
        align-items: center;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .self-photo {
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
    }

    .contact-list > li {
        justify-content: flex-start;
    }
}
