.drawer {
    position: fixed;
    display: grid;
    grid-template-areas: "toggle ."
                         "content content";
    grid-template-columns: 3.5rem 1fr;
    grid-template-rows: 3.5rem 1fr;
    background-color: var(--indigo);
    color: var(--cultured);
    height: 100vh;
    width: 300px;
    top: 0;
    left: -300px;
    transition-property: left;
    transition-duration: 0.25s;
    z-index: 1000;
}

.drawer-visible {
    left: 0;
}

.drawer-close-toggle {
    grid-area: toggle;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3.5rem;
    width: 3.5rem;
    font-size: 1.5rem;
    cursor: pointer;
    background-color: var(--indigo);
    color: var(--cultured);
    border: none;
}

.drawer-close-toggle:hover,
.drawer-close-toggle:focus {
    background-color: var(--purple);
    outline: none;
}

.drawer-content {
    grid-area: content;
    overflow-y: auto;
}
